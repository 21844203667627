import React from 'react';
import styles from './home.module.scss';
import Section from '@/components/Section';
import Image from 'next/image';
import binance_round_img from '#/images/binance_round.png';
import deribit_round_img from '#/images/deribit_round.png';
import bybit_round_img from '#/images/bybit_round.png';
import woo_round_img from '#/images/woo_round.png';
import phemex_round_img from '#/images/phemex_round.png';
import bitget_round_img from '#/images/bitget_round.png';
import blofin_round_img from '#/images/blofin_round.png';
import { useTranslation } from 'next-i18next';

const exchanges = [
    { src: bybit_round_img },
    { src: binance_round_img },
    { src: blofin_round_img },
    { src: bitget_round_img },
    { src: woo_round_img },
    { src: phemex_round_img },
    { src: deribit_round_img },
];
const ExchangesSection = () => {
    const { t } = useTranslation('home', { keyPrefix: 'exchanges_section' });
    return (
        <Section>
            <div className={styles.exchangeWrapper}>
                <div className={styles.exchangeTextWrapper}>
                    <h2 className={`as-h2 desktop`}>{t('title' as any)}</h2>
                    <p className={'as-h3 mobile'}>{t('title' as any)}</p>
                    <p className="body">{t('subtitle' as any)}</p>
                </div>
                <div className={styles.exchangeLogosWrapper}>
                    {exchanges.map((image, index) => (
                        <Image
                            key={'exchange_logo_key' + index}
                            src={image.src}
                            alt={t('images_alt')}
                            className={styles.exchangeLogoImg}
                        />
                    ))}
                </div>
            </div>
        </Section>
    );
};

export default ExchangesSection;
