import { localAxiosInstance } from '@/lib/axiosInstance';
import { AxiosResponse } from 'axios';

interface AnonymousRequestPayload<T> {
    data: T;
    token: string;
}

interface SupportRequestPayload {
    username?: string;
    userEmail: string;
    text: string;
    verification: string;
}
type RegisterClickPayload = {
    target: `${string}_unlocked_click_static`;
};

export const registerAnonymous = (): Promise<AxiosResponse<{ anonymous: string }>> =>
    localAxiosInstance.post('/api/anonymous/', {
        event: 'auth',
    });

export const submitSupportRequest = (
    payload: AnonymousRequestPayload<SupportRequestPayload>,
): Promise<AxiosResponse<{ message: string }>> => {
    return localAxiosInstance('/api/support/', {
        data: payload.data,
        method: 'POST',
        headers: { Authorization: `Bearer ${payload.token}` },
    });
};

export const disableDraftMode = ({ token }: { token: string }): Promise<AxiosResponse<void>> =>
    localAxiosInstance('/api/disable-draft/', {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    });

export const registerClick = ({ data, token }: AnonymousRequestPayload<RegisterClickPayload>) =>
    localAxiosInstance('/api/register-unlocked/', {
        method: 'POST',
        data,
        headers: { Authorization: `Bearer ${token}` },
    });
