import React from 'react';
import styles from './home.module.scss';
import Section from '@/components/Section';
import {
    ImageWrapper,
    LightSection,
    LightSectionButtonContainer,
    LightSectionTextWrapper,
    LightSectionTitle,
    LightSubSection,
} from '@/components/Shared/ContentSection';
import { Button } from '@/components/Buttons';
import { getRefLink } from '@/lib/helpers';
import tradingTools from '#/images/home/trading_tools.png';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const ImportSection = () => {
    const { t } = useTranslation(['home', 'common']);
    return (
        <Section>
            <LightSection marginBottom marginTop className={styles.importSection}>
                <LightSubSection>
                    <LightSectionTextWrapper>
                        <LightSectionTitle className={styles.sectionTitle}>
                            {t('home:import_section.title' as any)}
                        </LightSectionTitle>
                        <h3 className={`as-h4 ${styles.sectionSubTitle}`}>
                            {t('home:import_section.subtitle' as any)}
                        </h3>
                        <p>{t('home:import_section.description' as any)}</p>
                        <LightSectionButtonContainer>
                            <Button
                                className={'lightSectionButton'}
                                to={getRefLink()}
                                size={'large'}
                                mobileSize={'full'}
                                color={'primary'}
                                blank={false}
                            >
                                {t('common:get_started_button_label' as any)}
                            </Button>
                        </LightSectionButtonContainer>
                    </LightSectionTextWrapper>
                </LightSubSection>
                <LightSubSection>
                    <ImageWrapper>
                        <Image
                            src={tradingTools}
                            alt={t('home:import_section.section_image_alt' as any) as string}
                            quality={100}
                            className="section-image"
                        />
                    </ImageWrapper>
                </LightSubSection>
            </LightSection>
        </Section>
    );
};
export default ImportSection;
