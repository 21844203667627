import React, { PropsWithChildren } from 'react';
import styles from './icon-list.module.scss';
import CheckIcon from '#/images/icons/vIcon.svg';

interface IconListItemProps {
    icon?: React.ReactNode | JSX.Element;
    textClassName?: string;
    light?: boolean;
}
const CheckIconItem: React.FC<PropsWithChildren<IconListItemProps>> = ({
    children,
    icon,
    textClassName = '',
    light = true,
}) => {
    return (
        <div className={styles.checkIconWrapper}>
            {icon || <CheckIcon className={styles.icon} />}
            <p
                className={`${styles.text} ${
                    light ? styles.textLight : styles.textDark
                } ${textClassName}`}
            >
                {children}
            </p>
        </div>
    );
};

export default CheckIconItem;
