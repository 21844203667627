import axios, { AxiosInstance } from 'axios';
import { captureException } from '@sentry/nextjs';

const timeoutSeconds = 45;

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API,
    timeout: timeoutSeconds * 1000,
    timeoutErrorMessage: `Time out, server did not respond in ${timeoutSeconds}s `,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const serverFetchAxiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API,
    timeout: timeoutSeconds * 1000,
    timeoutErrorMessage: `Time out, server did not respond in ${timeoutSeconds}s `,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-app': 'static',
        Authorization: 'Bearer ' + process.env.SERVER_FETCH_TOKEN,
    },
});

export const localAxiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_HOST,
    timeout: timeoutSeconds * 1000,
    timeoutErrorMessage: `Time out, server did not respond in ${timeoutSeconds}s `,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Local-Request': new Date().getTime(),
        'no-request-id': new Date().getTime(),
    },
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error?.response?.status || null;
        if (status === 500 || status === 400 || status === 403) {
            // Sentry capture exception
            captureException(error, {
                tags: {
                    type: 'http',
                    status,
                    url: error?.response?.config?.url || 'unknown',
                    requestId: error?.response?.data?.requestId || 'unknown',
                },
            });
        }
        return Promise.reject(error);
    },
);

localAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error?.response?.status || null;
        if (status === 500 || status === 400 || status === 403) {
            // Sentry capture exception
            captureException(error, {
                tags: {
                    type: 'http',
                    status,
                    url: error?.response?.config?.url || 'unknown',
                    requestId: error?.response?.data?.requestId || 'unknown',
                },
            });
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
