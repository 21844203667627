import { useEffect } from 'react';
import { registerAnonymous } from '@/clientApi/localApi';
import { differenceInMinutes } from 'date-fns';

const env = process.env.NEXT_PUBLIC_ENVR || 'dev';
const storageKey = `@${env}_cmm_local_settings`;

export const registerToken = async () => {
    try {
        if (typeof window !== 'undefined') {
            const response = await registerAnonymous();

            const localStorage = window.localStorage;
            if (response?.data?.anonymous) {
                localStorage.setItem(
                    storageKey,
                    JSON.stringify({ anonymous: response.data.anonymous, timestamp: Date.now() }),
                );
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};
const useAnonymous = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const localStorage = window.localStorage;
            const settings = localStorage.getItem(storageKey);
            if (settings) {
                const { anonymous: token, timestamp } = JSON.parse(settings);
                if (!token) {
                    registerToken();
                }

                if (token && timestamp) {
                    const diff = differenceInMinutes(new Date(), new Date(timestamp));
                    if (diff > 59) {
                        registerToken();
                    }
                }
            } else {
                registerToken();
            }
        }
    });
};

export const getToken = (): { token: string; timestamp: null | number } => {
    const result: { token: string; timestamp: null | number } = {
        token: '',
        timestamp: null,
    };
    if (typeof window !== 'undefined') {
        const localStorage = window.localStorage;
        const settings = localStorage.getItem(storageKey);
        if (settings) {
            const { anonymous, timestamp } = JSON.parse(settings);
            result.token = anonymous;
            result.timestamp = Number(timestamp);
        }
    }

    return result;
};

export const resetToken = () => {
    if (typeof window !== 'undefined') {
        const localStorage = window.localStorage;
        localStorage.removeItem(storageKey);
    }
};

export default useAnonymous;
