import React from 'react';
import styles from './home.module.scss';
import Section from '@/components/Section';
import {
    ImageWrapper,
    LightSection,
    LightSectionButtonContainer,
    LightSectionGridWrapper,
    LightSectionTitle,
    LightSubSection,
} from '@/components/Shared/ContentSection';
import Image from 'next/image';
import securityImage from '#/images/home/security.png';
import CheckIconItem from '@/components/IconList/CheckIconItem';
import { getRefLink } from '@/lib/helpers';
import { Button } from '@/components/Buttons';
import { useTranslation } from 'next-i18next';

const SecuritySection: React.FC = () => {
    const { t } = useTranslation(['home', 'common']);
    const features = [
        t('home:security_section.feature_1' as any),
        t('home:security_section.feature_2' as any),
        t('home:security_section.feature_3' as any),
        t('home:security_section.feature_4' as any),
    ];

    return (
        <Section>
            <LightSection marginBottom marginTop>
                <LightSubSection>
                    <LightSectionTitle className={`mobile ${styles.sectionTitle}`}>
                        {t('home:security_section.title' as any)}
                    </LightSectionTitle>
                    <ImageWrapper>
                        <Image
                            src={securityImage}
                            alt={t('home:security_section.section_image_alt' as any) as string}
                            quality={100}
                            className="section-image"
                        />
                    </ImageWrapper>
                </LightSubSection>
                <LightSubSection gap="0">
                    <LightSectionTitle className={`desktop`}>
                        {t('home:security_section.title' as any)}
                    </LightSectionTitle>
                    <p>{t('home:security_section.description' as any)}</p>
                    <LightSectionGridWrapper>
                        <div
                            className={`grid ${styles.analyticsGrid} ${styles.securitySectionCheckList}`}
                        >
                            {features.map((feature) => (
                                <CheckIconItem key={feature} light={false}>
                                    {feature}
                                </CheckIconItem>
                            ))}
                        </div>
                    </LightSectionGridWrapper>
                    <LightSectionButtonContainer>
                        <Button
                            className={'lightSectionButton'}
                            to={getRefLink()}
                            size={'large'}
                            mobileSize={'full'}
                            color={'primary'}
                            blank={false}
                        >
                            {t('common:get_started_button_label' as any)}
                        </Button>
                    </LightSectionButtonContainer>
                </LightSubSection>
            </LightSection>
        </Section>
    );
};

export default SecuritySection;
