import React from 'react';
import Section from '@/components/Section';
import styles from './home.module.scss';
import { MainSection, MainSubSection } from '@/components/Shared';
import CheckIconItem from '@/components/IconList/CheckIconItem';
import { Button } from '@/components/Buttons';
import Link from '@/components/Link';
import { getRefLink } from '@/lib/helpers';
import { ROUTES } from '@/lib/constants.mjs';
import Image from 'next/image';
import journalingImage from '#/images/home/journaling.png';
import { RectBg } from '@/components/Backgrounds';
import { Trans, useTranslation } from 'next-i18next';

const JournalingSection = () => {
    const { t } = useTranslation(['home', 'common']);
    const features = [
        t('home:journaling_section.feature_1' as any),
        t('home:journaling_section.feature_2' as any),
        t('home:journaling_section.feature_3' as any),
        t('home:journaling_section.feature_4' as any),
    ];

    return (
        <Section className="fillBgSkewBoth" innerClassName="unSkew">
            <MainSection marginBottom marginTop className={`${styles.journalingSection}`}>
                <MainSubSection gap="0">
                    <h2 className={`as-h1 ${styles.journalingTitle} desktop`}>
                        {t('home:journaling_section.title' as any)}
                    </h2>
                    <p>
                        <Trans
                            i18nKey={'home:journaling_section.description'}
                            components={{ b: <b /> }}
                            defaults={
                                'Identify patterns of success and failure through our fully <b>automated journaling</b> & <b>trade analytics</b> platform. Autosync your full trade history through Read-Only API keys with a single click and begin tracking <b>instantly</b>.'
                            }
                        />
                    </p>
                    <div className={`grid ${styles.journalGrid}`}>
                        {features.map((feature) => (
                            <CheckIconItem key={feature}>{feature}</CheckIconItem>
                        ))}
                    </div>
                    <div className={styles.journalingButtonContainer}>
                        <Button
                            size={'large'}
                            mobileSize={'full'}
                            color={'light'}
                            to={getRefLink()}
                            className={styles.button}
                            blank={false}
                        >
                            {t('common:get_started_button_label' as any)}
                        </Button>
                        <Link
                            color={'light'}
                            label={
                                t('home:journaling_section.journaling_link_label' as any) as string
                            }
                            icon
                            to={ROUTES.JOURNALING}
                        />
                    </div>
                </MainSubSection>
                <MainSubSection>
                    <p className={`as-h1 ${styles.journalingTitle} mobile`}>
                        {t('home:journaling_section.title' as any)}
                    </p>
                    <RectBg className={`${styles.journalingImageWrapper}`}>
                        <Image
                            src={journalingImage}
                            alt={t('home:journaling_section.section_image_alt' as any) as string}
                            quality={100}
                            className="section-image"
                        />
                    </RectBg>
                </MainSubSection>
            </MainSection>
        </Section>
    );
};

export default JournalingSection;
