import React from 'react';
import Section from '@/components/Section';
import styles from './homePageBFTopSectSection.module.scss';
import { MainSection, MainSubSection } from '@/components/Shared';
import BfTitle from '@/pageComponents/BlackFridayComponents/BFTitle';
import Button from '@/components/Buttons/Button';
import { ROUTES } from '@/lib/constants.mjs';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import topImage from '#/images/black_friday/bf_top_hero.png';

const HomePageBfTopSectSection = () => {
    const { t } = useTranslation('black-friday');
    return (
        <Section padding={'0'} withHeader className={styles.topSection}>
            <MainSection
                defaultDirection={'column'}
                mobileFlexDirection="column"
                className={styles.mainTopSection}
            >
                <MainSubSection gap={'16px'} className={styles.mainTopSubSection}>
                    <BfTitle />
                    <Button className={styles.TopSectionButton} asAnchor to={ROUTES.REGISTRATION}>
                        {t('claim_your_60_off')}
                    </Button>
                </MainSubSection>
                <MainSubSection className={styles.imageContainer}>
                    <Image
                        className={styles.topImage}
                        src={topImage}
                        alt={t('home:top_section.section_image_alt')}
                    />
                </MainSubSection>
            </MainSection>
        </Section>
    );
};

export default HomePageBfTopSectSection;
