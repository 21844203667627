import { useEffect } from 'react';
import useAnonymous, { getToken } from '@/hooks/useAnonymous';
import { disableDraftMode } from '@/clientApi/localApi';

const useDisableDraftMode = (draftMode: boolean | undefined) => {
    useAnonymous();
    const performDisableDraftMode = async () => {
        if (!draftMode) return;
        const { token } = getToken();
        await disableDraftMode({ token });
    };

    useEffect(() => {
        if (!draftMode) return;
        setTimeout(() => {
            performDisableDraftMode();
        }, 4000);
    }, []);
};

export default useDisableDraftMode;
