import React, { PropsWithChildren } from 'react';

const RectBg: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className = '',
}) => {
    return (
        <div
            className={className}
            style={{
                backgroundImage: 'url(images/backgrounds/rect_bg.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}
        >
            {children}
        </div>
    );
};

export default RectBg;
